.dialog-wide {
  max-width: 30rem;
}

@media screen and (max-width: 768px) {
  .modal-dialog {
    pointer-events: unset;
    .modal-content {
      max-height: 70vh;
      overflow-y: scroll;
    }
  }
}
