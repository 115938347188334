.title-container {
  display: flex;

  .links-container {
    display: flex;
    align-items: center;

    .nav-link {
      padding: 0 9px;
      display: flex;
      align-items: center;

      .nav-text {
        margin-left: 4px;
      }
    }
  }
}

td.link-icon-container {
  vertical-align: middle !important;
  text-align: center;
}

.loading-container {
  margin-left: 0.5rem;
  align-self: center;
}
