.students-assisted {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  .student-info {
    width: 80%;
  }
}

.student-button-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.2rem 0.25rem 0.5rem;

  .student-button {
    margin: 0 0.2rem 0.2rem;
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}
