.modal-content .modal-body {
  overflow-y: auto;
  max-height: 80vh;

  .modal-note {
    margin: 1rem;
    text-align: left;
  }
}

.modal-dialog {
  --bs-modal-width: 30vw;
  --bs-modal-max-width: none;
}

@media (max-width: 1499px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 1500px) {
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin: 1.75rem auto;
  }
}
