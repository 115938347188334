@import "../../../Assets/styles/globals.scss";

.tag-form-container {
  margin-bottom: 1rem;

  .selector-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 0.2rem;

    &.selected {
      border: 0.5px dashed #333;
    }
  }
}

.title-holder {
  display: flex;
}

.loading {
  margin-left: 0.5rem;
  align-self: center;
}

.badge {
  &.bg-info {
    background-color: $brand-light-blue !important;
    color: $brand-primary !important;
  }

  &.bg-danger {
    background-color: $brand-danger !important;
    color: $white !important;
  }

  &.bg-warning {
    background-color: $brand-warning !important;
  }
}

.form-select {
  margin-left: 0.2rem;
  border-radius: 5px;
}
