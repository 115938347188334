.main-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 10px 40px;
  background-color: #f8f9fa;

  a.tech-support {
    float: right;
  }
}
