@import "../../Assets/styles/globals.scss";

.blurb {
  font-weight: 400;
  color: $gray-med;
  font-size: 12px;
}

.message {
  font-weight: 400;
  color: $gray-med;
  font-size: 14px;
}

h3 {
  color: $gray-med;
  font-weight: 600;
}

.queue-wait-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.wait-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid $gray-med;
  margin-top: 10px;
  width: 180px;
  height: 180px;
}
