.show-button {
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 2rem;
  &:hover {
    color: #0d6efd;
  }
  &.more {
    background-image: linear-gradient(to top, #ffffff, #e5e5e5, #cccccc, #b3b3b3, #9b9b9b);
  }
  &.less {
    background-image: linear-gradient(to bottom, #ffffff, #e5e5e5, #cccccc, #b3b3b3, #9b9b9b);
  }
}
