.assistances {
  width: 100%;
  padding: 1.5rem;

  .assistance {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    border: solid 1px transparent;

    .section-header {
      font-size: large;
    }

    .details-container,
    .assistance-request {
      border: solid 1px #ddd;
      border-radius: 4px;
      padding: 4px;
    }

    .assistance-request {
      margin-bottom: 10px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dee2e6;
    }

    &:hover,
    &.expanded {
      border: solid 1px #ffc107;
      border-radius: 4px;
    }

    &.expanded {
      flex-direction: column;
      margin: 5px 0;

      .top {
        display: flex;

        .teacher-pic {
          width: 100px;
          margin: 0 10px 0 0;

          img {
            width: 100%;
            border: solid 2px black;
            border-radius: 4px;
          }
        }

        & > div:not(.teacher-pic) {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .header {
            strong {
              font-size: x-large;
            }
            span {
              float: right;
            }
          }
        }
      }

      .assistance-request-details {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }

      .assistance-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;

        div {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }

      .issue {
        display: grid;
        grid-template-columns: 1fr 3fr;
        margin: 10px 0;
      }

      .assistance-flags {
        display: flex;
        flex-direction: column;
        margin: 0 0 10px 0;

        .flags {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 15px;
        }
      }

      .notes {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }
    }

    &:not(.expanded) div {
      white-space: nowrap;
    }

    em.notes.truncated {
      margin: 0 0 0 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .message {
    margin: 0.5em;
  }
}

@media (max-width: 768px) {
  .assistances {
    .assistance {
      font-size: small;

      &:not(.expanded) {
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.expanded {
        .top {
          & > div:not(.teacher-pic) {
            .header {
              strong {
                font-size: large;
              }
            }
          }
        }
        .assistance-details {
          margin: 5px 0;
        }
        .issue {
          display: flex;
          flex-direction: column;
          margin: 0 0 5px;
        }
        .assistance-flags {
          .flags {
            grid-template-columns: 80% 15%;
            column-gap: 5%;

            span:nth-child(even) {
              display: flex;
              align-items: flex-end;
            }
          }
        }
        .notes {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
