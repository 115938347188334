.title-container {
  display: flex;

  .loading-container {
    margin-left: 0.5rem;
    align-self: center;
  }
}

.edit-options {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
