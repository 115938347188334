@import "../../Assets/styles/globals.scss";

main {
  margin-top: calc($paddings-heavy * 2 + 1rem);

  .chat-container {
    background-color: #36393f;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
  }
}

.chat-title {
  color: #fff;
}

.chat-message {
  color: #fff;
  margin-bottom: 12px;
}

.rudderbot-message {
  background-color: #303030;
  color: #fff;
  padding: 10px;
  margin-bottom: 12px;
}

.reco-message {
  background-color: #303030;
  color: #fff;
  padding: 10px;
  margin-bottom: 12px;
}

.chat-timestamp {
  color: #72767d;
}

.chat-author-rudderbot {
  color: #fff;
  font-weight: bold;
}

.chat-author-llm_bot,
.chat-author-recommended-ar {
  color: #f92f66;
  font-weight: bold;
}

.chat-author-mentor {
  color: #2eca70;
  font-weight: bold;
}

.chat-author-student {
  color: #71f072;
  font-weight: bold;
}

.chat-code-block {
  background: #1e1e1e;
  color: #d4d4d4;
  font-size: 13px;
  font-family: Menlo, monospace;
  line-height: 1.5;
  direction: ltr;
  text-align: left;
  white-space: pre;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}
