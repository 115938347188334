@import "../../../Assets/styles/globals.scss";

.login-button {
  font-family: ProximaNova, "Avenir Next", -apple-system-body, "Helvetica Neue";
  background-color: $dark;
  color: $white;
  text-decoration: none;
  padding: 15px 32px 15px 32px;
  display: flex;
  justify-content: space-around;
  margin: 1rem;
  font-size: 18px;
  font-weight: 400;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  line-height: 1;
}
