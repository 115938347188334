@import "globals";
@import "z-indexes";
@import "queue-base";
@import "idle-modal";

.queue-by-location {
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .queue-header {
    position: sticky;
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin-top: -1rem;
    top: 2.5rem;
    background-color: white;
    z-index: var(--queue-header-layer);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navigation {
      display: flex;
      align-items: center;
      .home-btn i {
        margin: 0;
        color: rgba(0, 0, 0, 0.5);
      }
      i {
        cursor: pointer;
        margin: 0.5rem 0rem 0 0.5rem;
        font-size: 1.4rem;
        color: $brand-primary;

        &:hover {
          color: $buoy-orange;
        }
      }
    }
    .queue-title {
      font-family: $brand-script;
      margin: 0;
      color: $brand-primary;
    }
    .actions {
      display: flex;
      align-items: center;
      .spacer {
        display: inline-block;
        width: 20px;
      }
    }
  }
  .queue-header::before {
    content: "";
    position: absolute;
    left: -100vw;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: white;
    z-index: var(--queue-header-layer);
  }
  .queue-selector {
    .card-header {
      align-items: center;
      padding-top: 0;
      padding-bottom: 0.5rem;
    }
    .options {
      display: flex;
      align-items: center;
      max-width: 70%;
      .option {
        width: 45px;
        height: 45px;
        border-width: 1px;
        border-style: solid;
        border-color: $buoy-orange;
        border-radius: 10px;
        margin: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: $brand-primary;
        }

        &:hover {
          border-width: 3px;
        }
        &.selected {
          border-width: 5px;
        }
        &.mentor {
          border-color: $brand-success;
        }
        &.busy {
          border-color: $brand-warning;
        }
        &.all {
          font-size: 1.2rem;
          background-color: lighten($buoy-orange, 25%);
        }
      }
      .mentors {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
  .queue-row {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: space-between;
  }
  .queue-column {
    display: flex;
    flex-direction: column;
    min-height: 0px;
    min-width: 350px;
    flex: 1 1 auto;

    &.left {
      margin-right: 1rem;
    }
    .card-header {
      background-color: #f7f7f7;
      position: sticky;
      z-index: var(--card-header-layer);
      top: 7.8rem;
      display: flex;
      justify-content: space-between;
    }
    .card-header-border {
      height: 0.25rem;
      border: 1px solid #dfdfdf;
      border-bottom: 0px;
      border-radius: 0.25rem 0.25rem 0 0;
      width: calc(100% + 2px);
      margin-left: -1px;
      margin-top: -0.25rem;
      position: sticky;
      z-index: var(--card-header-border-layer);
      top: 8rem;
    }
    .card-header-back {
      height: 0.35rem;
      width: calc(100% + 2px);
      margin-left: -1px;
      position: sticky;
      top: 7.8rem;
      border: 0.2rem solid white;
      border-top: 0.25rem solid white;
      border-bottom: 0;
      z-index: var(--card-header-back-layer);
    }
    .card {
      background-color: #f7f7f7;
      min-height: 50px;
      border-top: 0px;
    }
  }
  .queue-top {
    flex: 1 1 auto;
  }
  .queue-mid {
    flex: 1 2 auto;
  }
  .queue-bottom {
    flex: 1 3 auto;
    min-height: 180px;
  }
  .list-group {
    max-height: 100%;
  }
}
.fa-spinner.queue-loader {
  font-size: 1.4rem;
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: $small) {
  .padded {
    padding: 0.2rem;
  }

  .queue-by-location {
    max-width: 99%;

    .queue-row {
      flex-direction: column;
    }
    .queue-column {
      min-width: 0;
      &.right {
        min-width: 0;
      }
      &.left {
        margin-right: 0rem;
      }
    }
  }
}

@media screen and (min-width: $small) and (max-width: $medium) {
  .padded {
    padding: 1.5rem;
  }

  .queue-by-location {
    max-width: 90%;

    .queue-row {
      flex-direction: column;
    }
    .queue-column {
      min-width: 0;
      .card {
        min-height: 45px;
      }
      &.right {
        min-width: 0;
      }
      &.left {
        margin-right: 0rem;
      }
    }
  }
}

@media screen and (min-width: $medium) and (max-width: $large) {
  .queue-by-location {
    max-width: 80%;
  }
}

@media screen and (min-width: $large) {
  .queue-by-location {
    max-width: 75%;
  }
}

.feedbacks-list-absolute {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 3.5rem);
  padding: 2rem;
  padding-top: 0;
  left: 0;
  top: 3.5rem;
  overflow-y: scroll;
}

.queue-time-filter {
  i {
    font-size: 1.5rem;
    margin-left: 1rem;
    color: #dfdfdf;
    &.selected {
      color: #007bff;
    }
  }
}

.national-queue-stats {
  display: flex;
  flex-direction: column;
  background-color: white;
  h5 {
    margin: 0;
  }
  .group {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 0px;
    min-height: 80px;
    justify-content: space-between;
    .info {
      display: flex;
      justify-content: center;
      width: 100%;
      p {
        margin: 0;
      }
      .user-queue-activity {
        display: flex;
        justify-content: center;
        width: 100%;
        .hour-block {
          display: flex;
          flex-direction: column;
          .half-hours {
            width: 100%;
            height: 50px;
            border: 1px solid #f7f7f7;
          }
        }
      }
      .tasks {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-top: -1rem;
        flex-wrap: wrap;
        .stat {
          display: flex;
          margin: 1rem 0.5rem 0 0.5rem;
        }
        .quantity {
          display: flex;
          align-items: center;
          flex-direction: column;
          h2 {
            margin: 0;
            font-size: 2rem;
            line-height: 1.5rem;
          }
        }
        i {
          font-size: 2rem;
        }
      }
      .scores {
        display: flex;
      }
    }
  }
  .alert {
    height: auto;
    margin-bottom: 1rem;
    animation-name: hide-alert;
    animation-duration: 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    overflow: hidden;
    max-height: 200px;
  }
  @keyframes hide-alert {
    from {
      opacity: 1;
    }
    to {
      height: 0px;
      margin: 0px;
      padding: 0px;
      opacity: 0;
    }
  }
  .queue-weights {
    display: flex;
    flex-direction: column;
    margin-bottom: -1rem;
    .option {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      label {
        line-height: 1rem;
        margin: 0;
      }
      input {
        max-width: 8rem;
      }
      .description {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
