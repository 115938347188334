@import "src/Assets/styles/globals";

.accuracy-slider {
  .slider-range {
    direction: rtl;
  }

  .slider-labels {
    display: flex;
    flex-flow: row wrap;
    color: $secondary;

    .label-left {
      width: 33.33333%;
      text-align: left;
    }
    .label-center {
      width: 33.33333%;
      text-align: center;
    }
    .label-right {
      width: 33.33333%;
      text-align: right;
    }

    .selected {
      color: #212529; // matches bootstrap default text color
    }
  }
}
