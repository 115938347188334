@import "../Assets/styles/globals";

.icons-list {
  display: flex;
  flex-wrap: wrap;
}

.social-icons .list-inline-item {
  margin: 0;
}

.social-icon-muted {
  color: $secondary;
  font-size: 0.9em;
  opacity: 0.1;
}

.social-icon-muted:hover {
  color: inherit;
  opacity: 1;
}
