@import "../../../Assets/styles/z-indexes.css";

.embedded {
  display: flex;
  z-index: var(--launch-layer);
  top: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  li {
    list-style: none;
  }
  a:not(.btn) {
    padding: 0.5rem;
    color: #9a9d9f !important;
  }

  &.nav {
    @media (max-width: 768px) {
      & {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.center {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
