@import "../Assets/styles/globals.scss";

.student-info-container {
  display: flex;
  align-items: center;
  width: 100%;

  .student-info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $medium) {
      flex-direction: column;
    }

    .student-details-container {
      text-align: right;

      .badge {
        height: 18px;
        margin-bottom: 4px;

        &.info {
          background-color: $brand-light-blue !important;
          color: $brand-primary !important;
        }

        &.bg-danger {
          background-color: $brand-danger !important;
        }

        &.bg-warning {
          background-color: $brand-warning !important;
        }
      }

      .details {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        text-align: right;
        position: relative;
        top: -6px;

        @media (max-width: 576px) {
          text-align: left;
          top: 0px;
          margin-top: 0.5rem;
        }
      }
    }
  }
}

.rudder-tag {
  text-overflow: ellipsis;
  max-width: 230px;
  overflow: hidden;
}
