.queue-show {
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .btn-refresh.rotating {
    i {
      animation: rotating 2s linear infinite;
    }
  }

  .pronoun {
    font-style: italic;
    color: $dark;
    font-size: 0.7em;
  }

  nav.nav-pills {
    span.badge {
      position: relative;
      top: -2px;
    }
  }

  .queue-container {
    transition: opacity 0.3s ease-in-out;

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      *:focus {
        outline: 0;
      }
    }
  }

  .card {
    margin-bottom: 14px;

    .card-header {
      user-select: none;
      cursor: pointer;
    }

    .list-group {
      transition: opacity 0.3s ease-in-out;
      overflow: hidden;
      display: grid;
      align-items: stretch;
    }

    &.collapsed {
      opacity: 0.4;

      .list-group {
        opacity: 0;
        height: 0px;
      }
    }
  }

  li.list-group-item {
    transition: opacity 0.3s ease-in-out;
    position: relative;
    padding-left: 30px;
    border-left: 0;
    border-right: 0;
    border-width: 2.5px;

    &.thick-dividers {
      border-width: 5px;
      border-color: $gray-lighter;
    }

    &:hover {
      .type {
        background-color: darken($queue-assistance, 30%);
      }
    }

    .type {
      background-color: $queue-assistance;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.assistance {
      &.standout {
        background-color: lighten($queue-assistance, 25%);
      }

      &:hover {
        .type {
          color: $white;
          background-color: darken($queue-assistance, 30%);
        }
      }

      .type {
        background-color: $queue-assistance;
      }
    }

    &.task {
      &:hover {
        .type {
          background-color: $queue-task-request;
          color: $brand-primary;
        }
      }

      .type {
        background-color: darken($queue-task-request, 10%);
        color: $brand-primary;
      }
    }

    &.request {
      &.priority {
        background-color: lighten($brand-warning, 45%);
      }

      &:hover {
        .type {
          color: $white;
          background-color: darken($queue-assistance, 10%);
        }
      }

      .type {
        color: $white;
        background-color: $queue-assistance;
      }
    }

    &.queue-larry-ai-only {
      &:hover {
        .type {
          background-color: lighten($dark, 25%);
        }
      }

      background-color: rgba(200, 200, 197, 0.06);
      .type {
        background-color: lighten($dark, 30%);
        color: $white;
      }
    }

    &.queue-task {
      &:hover {
        .type {
          background-color: darken($queue-task-request, 10%);
          color: $brand-primary;
        }
      }

      .type {
        background-color: $queue-task-request;
        color: $brand-primary;
      }

      .blurb {
        margin-bottom: 1.5rem;
      }

      .actions {
        position: absolute;
        top: auto;
        bottom: 0;
        right: 0;

        .btn {
          position: relative;
          bottom: 0;
        }
      }
    }

    &.queue-evaluation {
      &.priority {
        background-color: lighten($brand-warning, 45%);
      }

      &:hover {
        .type {
          background-color: $brand-info;
        }
      }

      .type {
        background-color: lighten($brand-info, 10%);
      }
    }

    &.queue-tech-interview {
      &:hover {
        .type {
          background-color: $brand-main;
          color: $light;
        }
      }

      .type {
        background-color: lighten($brand-main, 10%);
        color: $light;
      }
    }

    &.interview-status {
      &:hover {
        .type {
          background-color: $brand-main;
        }
      }

      .type {
        background-color: lighten($brand-main, 10%);
      }
    }

    .buffer {
      height: 20px;
    }

    z-index: auto;

    &:hover {
      z-index: auto;
    }

    img.avatar {
      width: 60px;
      height: 60px;
      float: left;
      margin-right: 8px;
      border-radius: 5px;
      border: 2px solid #34495e;
    }

    .info {
      .name {
        line-height: 1.2em;
        float: left;

        .resource-name {
          font-style: italic;
          font-size: 0.6em;
          line-height: 20px;
        }
      }

      .details {
        float: right;
        text-align: right;
        position: relative;
        top: -6px;
        .badge {
          margin-left: 2px;
        }
      }
    }

    .blurb {
      margin-top: 16px;
      padding-left: 6px;

      .resource-name {
        font-size: 0.9em;
      }

      blockquote {
        font-size: 0.9em;
        color: #555;
        font-style: italic;
        line-height: 1.5em;
        margin-bottom: 0px;
      }
    }

    .actions {
      position: relative;
      right: -1.25rem;
      top: 0.75rem;

      button.btn,
      a.btn {
        border-radius: 0px;
      }
    }

    .type {
      top: 0px;
      left: 0px;
      position: absolute;
      font-size: 0.8em;
      overflow: hidden;
      height: 100%;
      width: 22px;
      transition: background-color 0.1s ease-in-out;

      .text {
        transform: rotate(-90deg);
        transform-origin: left;
        left: 10px;
        top: 200px;
        position: relative;
        width: 200px;
        text-align: right;
        font-size: 0.7rem;
      }
    }

    .assistor {
      display: flex;
      padding-top: 1rem;
      padding-left: 1.5rem;
      font-size: 1rem;

      img.queue-avatar-img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
      }

      .name {
        display: inline-block;
      }
    }
  }

  .progress {
    position: relative;

    .progress-label {
      position: absolute;
      right: 14px;
      top: 25%;
    }
  }
}

@media (min-width: 768px) {
  .teacher-dropdown {
    .dropdown-menu {
      border-style: none;
      background-color: transparent;
    }
  }
}
