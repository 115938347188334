@import "src/Assets/styles/globals";

.keywords {
  display: flex;
  justify-content: space-between;
  .page-button {
    padding: 0.2rem;
    height: 4rem;
    border: 0;
    opacity: 80%;
    &.disabled {
      filter: opacity(30%);
    }
  }
  .btn-secondary {
    background-color: #494440;
    color: #ffffff;
  }
  .btn-info {
    background-color: #0c616e;
    color: #ffffff;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .btn {
      border-style: none;
      cursor: pointer;
      filter: opacity(40%);
      margin: 0.2rem;
      padding: 0.2rem;
      &.selected {
        filter: opacity(100%);
      }
    }
  }
}
