.navbar-queue {
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 10px;
  }
}

.navbar-nav .nav-link {
  &.btn {
    margin-left: 0.5rem;
  }
  &.btn-outline-primary {
    padding: 0.375rem 0.75rem !important;
    color: #007bff;
    & > * {
      color: inherit !important;
      &:hover {
        color: white;
      }
    }
  }
}

.content {
  margin-top: calc(1rem * 2 + 1.5rem);
  min-height: calc(100vh - (1rem * 2 + 1.5rem));
}

@media (min-width: 768px) {
  .non-tablet-label {
    display: none;
  }
}
