.my-assistances {
  width: 100%;

  .my-assistances-assistance {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    border: solid 1px transparent;

    .my-assistances-section-header {
      font-size: large;
      font-weight: bold;
    }

    .my-assistances-details-container {
      margin-top: 20px;
    }

    &:hover,
    &.expanded {
      border: solid 1px #ffc107;
      border-radius: 4px;
    }

    &.expanded {
      display: flex;
      flex-direction: column;
      margin: 5px 0;

      .my-assistances-top {
        display: flex;
        flex-direction: row;

        .student-pic {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin-right: 10px;
            width: 100px;
            border-radius: 10px;
          }
        }

        .my-assistances-assistance-request {
          border: solid 1px #ddd;
          border-radius: 4px;
          padding: 4px;
          margin-bottom: 10px;
        }
      }

      .my-assistances-assistance-request-details {
        width: 100&;
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 5px;
      }

      .my-assistances-assistance-details {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }
    }

    &:not(.expanded) div {
      white-space: nowrap;
    }

    em.notes.truncated {
      margin: 0 0 0 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .my-assistances-message {
    margin: 0.5em;
  }
}

@media (max-width: 768px) {
  .my-assistances {
    .assistance {
      font-size: small;

      &:not(.expanded) {
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.expanded {
        .assistance-details {
          margin: 5px 0;
        }
        .issue {
          display: flex;
          flex-direction: column;
          margin: 0 0 5px;
        }
        .notes {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
