@import "../Assets/styles/globals.scss";

.btn-toggle-duty {
  &.on-duty {
    color: $buoy-orange;
    border-color: $buoy-orange;

    &:hover {
      background-color: rgba($buoy-orange, 0.5);
      box-shadow: 0 0 2px $buoy-orange;
    }
  }

  &.off-duty {
    color: $brand-warning;
    border-color: $brand-warning;

    &:hover {
      background-color: rgba($brand-warning, 0.5);
      box-shadow: 0 0 2px $brand-warning;
    }
  }
}
