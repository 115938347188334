.opt-out-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 1rem;
}

.student-facing-warning {
  font-weight: bold;
  color: crimson;
}
