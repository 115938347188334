@import "../../../Assets/styles/globals.scss";

.off-duty-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 6rem;
  margin: 0;
  color: darken($buoy-orange, 30%);
  background-color: lighten($buoy-orange, 30%);
}
