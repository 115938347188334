@import "../Assets/styles/globals.scss";

.modal-dialog {
  --bs-modal-width: 30vw;
  --bs-modal-max-width: none;

  &.small {
    max-width: 600px;
  }

  &.medium {
    max-width: 700px;
  }

  .modal-header {
    background-color: $brand-primary;

    button {
      color: $white;
    }
  }

  .modal-title {
    color: $white;
  }

  .modal-content .modal-body {
    overflow-y: auto;
    max-height: 80vh;

    .modal-note {
      margin: 1rem;
      text-align: left;
    }
  }
}

@media (max-width: 1499px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 1500px) {
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin: 1.75rem auto;
  }
}
