.container {
  margin: 0 auto;
  width: 570px;
  display: flex;
  align-items: flex-start;

  p:nth-of-type(1) {
    margin-bottom: 8px;
  }

  p:nth-of-type(2) {
    margin: 0;
  }

  i.success-icon {
    margin: 0 3px 0 0;
  }

  .column {
    display: grid;
    flex-direction: column;
  }
}
