@import "src/Assets/styles/z-indexes";
@import "src/Assets/styles/globals";

.teacher-holder {
  max-height: 75vh;
  overflow-y: scroll;
  z-index: var(--mentor-status-layer);

  @media (max-width: 768px) {
    max-height: 35vh;
  }

  .teacher-image {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    img {
      width: 50px;
      min-height: 50px;
      background-color: $dark;
      height: auto;
      border-width: 2px;
      border-style: solid;
      border-radius: 10px;
      margin-right: 0.75rem;
    }

    li.teacher-mentor-item a {
      padding: 0;
    }

    .mentor {
      display: flex;
      padding: 5px 0;
      cursor: pointer;
      border-radius: 10px;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}
