.tabs-container {
  .tabs {
    display: flex;
    .tab {
      outline: none;
      border: 1px solid transparent;
      background-color: white;
      padding: 5px;
      border-bottom: 1px solid #dee2e6;
    }
    .tab.selected,
    .tab:hover {
      border: 1px solid #dee2e6;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    .tab.selected {
      border-bottom: none;
    }
  }

  .tabs::after {
    content: "";
    flex-grow: 1;
    border-bottom: 1px solid #dee2e6;
  }
}
