// ResponsiveLabel.scss

$sizes: ("xsmall", "small", "medium", "large");
$size-breakpoints: (
  "xsmall": 0,
  "small": 500px,
  "medium": 768px,
  "large": 1200px,
);

@each $size, $breakpoint in $size-breakpoints {
  @media (min-width: #{$breakpoint}) {
    .responsive-label.#{$size} {
      display: inline-block;
    }
  }

  .responsive-label:not(.#{$size}) {
    display: none;
  }
}
