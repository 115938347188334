.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: hidden;
  outline: 0;

  &.backdrop-blur {
    backdrop-filter: blur(50px);
  }

  &.backdrop-center {
    display: flex;
    align-items: center;
  }
}
