@import "../../Assets/styles/globals.scss";

.modal-dialog {
  max-width: 700;
}
.modal-body {
  padding: 1rem;
}

.btns {
  width: 100%;
  display: flex;

  .btn-outline-warning {
    padding: unset;
  }
}

th.aligned {
  vertical-align: middle;
}

td.aligned {
  vertical-align: middle;
}

.blurb {
  font-weight: 400;
  color: $gray-med;
  font-size: 16px;
}

h3 {
  color: $gray-med;
  font-weight: 600;
}

h4 {
  font-size: 1.4rem;
  color: $gray-med;
}
