.option-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .option-button {
    width: 30%;
    margin: 0 0 0.5rem;
    white-space: normal;
    font-size: 90%;

    @media only screen and (max-width: 700px) {
      width: 30%;
    }
  }
}
