@import "src/Assets/styles/globals";
@import "src/Assets/styles/z-indexes";

.number-rating {
  position: relative;

  .numbers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .number {
      width: 2.5em;
      height: 2.25em;
      border-radius: 50%;
      margin: 5px;
      border: none;
      z-index: var(--number-rating-layer);
      background-color: #efefef;

      &.selected {
        background-color: $brand-primary;
        color: $white;
      }

      &.disabled {
        outline: none;
        cursor: auto;
        color: $secondary;
      }
    }
  }

  &:after {
    background-color: $gray-light;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 48%;
    left: 0;
    right: 0;
    bottom: 48%;
    width: 95%;
  }
}
