@import "src/Assets/styles/globals";

.step {
  display: inline-block;
  width: 4rem;
  height: 0.4rem;
  margin-right: 0.2rem;
  background-color: transparent;
  border: 1px solid $secondary;
  border-radius: 0.2rem;
  user-select: none;

  &.active {
    background-color: $secondary;
  }
}
