:root {
  --emote-rating-after-layer: -1;
  --emote-rating-layer: 1;

  --number-rating-layer: 1;

  /* within Navbar stacking context */
  --mentor-status-layer: 1;

  --card-header-layer: 10;
  --card-header-back-layer: 11;
  --card-header-border-layer: 11;
  --queue-header-layer: 13;

  --launch-layer: 100;

  --navbar-layer: 100;
}
