.suggested-ars-list-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 16px 0;
  width: 100%;

  .requestors {
    display: flex;
    padding-left: 1rem;
    flex-direction: column;
    text-align: center;
  }

  .request {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 1rem;
    padding-right: 1rem;

    .request-badges {
      display: flex;
      flex-direction: row;
      gap: 0.4rem;
    }

    .start-time {
      margin-right: 0.25rem;
      font-weight: lighter;
      font-size: small;
    }

    .note {
      margin-top: 0.25rem;
      min-height: 1.5rem;
      font-style: italic;

      &.preview {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .button-link {
    &:hover {
      text-decoration: none;
    }
  }

  .join-button {
    display: flex;
    margin-top: 0.5rem;
    margin-right: 1rem;
  }

  .helpful-rating {
    font-size: medium;
    gap: 0.2rem;

    .fa-thumbs-up {
      color: green;

      &:hover {
        font-weight: bold;
      }
    }

    .fa-thumbs-down {
      color: darkred;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
