@import "./globals.scss";

.brand {
  margin: 0;
  font-weight: 500;
  margin-right: 1rem;
  font-family: $brand-script;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: $dark;
}
