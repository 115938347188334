$brand-secondary: #164a6d;
$brand-primary: #34495e;
$brand-danger: #62140c;
$brand-success: #0d6721;
$brand-warning: #eaaa20;
$brand-info: #13a2dd;
$brand-main: #304269;
$gray-light: #bdc3c7;
$gray-lighter: #95a5a6;
$gray-med: #555555;
$gray-dark: #343434;

$buoy-orange: #e87642;
$discord: #5865f2;
$brand-light-blue: #b8e3f5;

$queue-assistance: #91bed4;
$queue-task-request: #d9e8f5;

$blue: #007bff;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$cyan: #17a2b8;
$white: #fff;
$secondary: #6c757d;
$light: #e1e0e0;
$dark: #343a40;

$brand-script: Pacifico, cursive;

$paddings-light: 0.25rem;
$paddings-regular: 0.5rem;
$paddings-heavy: 1rem;
$paddings-very-heavy: 3rem;

$small: 300px;
$medium: 900px;
$large: 1400px;
$xlarge: 1800px;
