@import "../Assets/styles/globals.scss";

.calendly-card {
  margin-top: 0.5rem;
  border: 2px solid $cyan !important;
  border-radius: 0.25rem;
  cursor: pointer;
}

.calendly-card-body {
  display: flex;
  padding: 0.5rem;
}

.calendly-details {
  display: flex;
  flex: 1;
  justify-content: space-between;

  .calendly-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
