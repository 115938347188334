@import "src/Assets/styles/globals";

.star-rating {
  display: flex;
  flex-direction: column;

  .stars {
    display: flex;

    .star {
      background-color: transparent;
      border-style: none;
      cursor: pointer;
      font-size: 1.5rem;
      margin-right: 0.2rem;
      filter: grayscale(100%);

      .show-only {
        cursor: auto;
      }

      &.selected {
        filter: grayscale(0%);
      }

      &.disabled {
        opacity: 50%;
        outline: none;
        cursor: auto;
      }
    }
  }
}
