.home-btn {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-right: 1.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #e3e9f1;
  }

  &:visited {
    color: rgba(0, 0, 0, 0.5);
  }
}

.request-details-container {
  text-align: left;
}
