.content {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a,
  button,
  a:hover,
  button:hover {
    color: white;
  }

  .requirement {
    padding: 2em;
    background: #f8f9fa;
  }
}
