@import "../../Assets/styles/globals.scss";

.bold-label {
  font-weight: 700;
}

.text-1 {
  margin: 0;
}

.modal-title {
  color: $gray-med;
  font-size: 22.4;
  font-weight: 600;
}
