@import "src/Assets/styles/globals";

.assistance-buttons {
  display: flex;
  .btn-primary,
  .btn-outline-secondary,
  .cancel {
    outline: none;
    div {
      padding: 0.5rem;
    }
  }

  .btn-primary {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: #fff;
    padding: 0;
    &:hover {
      background-color: #0069d9;
      border-color: #0069d9;
      span:not(.badge) {
        color: #fff;
      }
    }
    span:not(.badge) {
      color: #fff;
    }
  }

  .cancel {
    border-color: $brand-warning;
    color: $brand-warning;
    &:hover {
      background-color: $brand-warning;
      color: black;
      span:not(.badge) {
        color: black;
      }
    }

    span:not(.badge) {
      color: $brand-warning;
      transition: color 0.15s ease-in-out;
    }
  }

  .btn-outline-warning {
    padding: 0.5em;
  }

  .btn-success {
    padding: 0.5em;
    i {
      color: $brand-success;
    }
  }

  .btn-outline-info {
    padding: 0.5em;
  }
}
