.suggested-ars-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 4rem;
  max-height: 50vh;
  overflow: auto;

  .suggested-requests-list {
    margin-left: 1rem;
  }
}
