@import "src/Assets/styles/globals";

.step-indicator-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 0px;
  margin-bottom: 1rem;

  .step-indicator {
    color: $secondary;
  }
}

.step-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}
