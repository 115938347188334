@import "../../Assets/styles/globals.scss";

.queue-btn {
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;

  &.view-details {
    margin: 0.4rem;
    background-color: $queue-assistance;
    border: 0.1rem solid darken($queue-assistance, 50%);
    border-radius: 0.2rem;
    color: darken($queue-assistance, 50%);
    font-size: 1rem;
  }

  &.conference {
    &.discordforum {
      color: lighten($discord, 35%);
      background-color: darken($discord, 20%);

      &:hover {
        color: lighten($brand-warning, 10%);
        background-color: darken($discord, 20%);
      }

      &.browser {
        color: darken($discord, 20%);
        background-color: lighten($discord, 30%);

        &:hover {
          color: darken($discord, 20%);
          background-color: lighten($brand-warning, 10%);
        }
      }
    }
  }

  &.main {
    color: $white;
    background-color: $brand-main;

    &:hover {
      background-color: lighten($brand-main, 30%);
    }
  }

  &.info {
    color: $white;
    background-color: $brand-info;

    &:hover {
      background-color: darken($brand-info, 10%);
    }
  }

  &.danger {
    color: $brand-primary;
    background-color: $queue-task-request;

    &:hover {
      color: lighten($brand-primary, 50%);
      background-color: darken($queue-task-request, 50%);
    }
  }

  &.success {
    color: $brand-primary;
    background-color: $queue-assistance;

    &:hover {
      color: $brand-primary;
      background-color: lighten($queue-assistance, 10%);
    }
  }

  &.warning {
    color: $dark;
    background-color: $brand-warning;

    &:hover {
      background-color: lighten($brand-warning, 10%);
    }
  }

  &.light {
    color: $brand-primary;
    background-color: lighten($brand-primary, 50%);

    &:hover {
      color: $white;
      background-color: lighten($brand-primary, 20%);
    }
  }

  &.external {
    color: $white;
    background-color: $brand-main;

    &:hover {
      background-color: lighten($brand-main, 30%);
    }
  }

  &.skip {
    color: $white;
    background-color: $brand-primary;

    &:hover {
      color: $brand-primary;
      background-color: lighten($brand-primary, 50%);
    }

    &.disabled {
      color: $brand-primary;
      background-color: lighten($brand-primary, 40%);
      pointer-events: none;
    }
  }

  &.manual {
    color: $dark;
    background-color: $light;

    &:hover {
      color: $light;
      background-color: $dark;
    }
  }
}
