@import "src/Assets/styles/globals";

.student-list {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  max-height: 40vh;
  overflow-y: auto;

  .student-item {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.2rem;

    .submit {
      margin-left: 0.25rem;
    }
  }

  .no-students {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    color: $secondary;
    width: 80%;
    border-radius: 0.5rem;
  }
}
