@import "src/Assets/styles/globals";
@import "src/Assets/styles/z-indexes";

.emote-rating {
  position: relative;

  .emotes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 3.5rem;

    .emote {
      background-color: transparent;
      color: $secondary;
      border: none;
      z-index: var(--emote-rating-layer);
      position: relative;
      line-height: 1rem;
      padding: 1px;
      cursor: pointer;

      &.angry {
        color: $red;
      }

      &.frown {
        color: $orange;
      }

      &.meh {
        color: $yellow;
      }

      &.smile {
        color: yellowgreen;
      }

      &.beam {
        color: $green;
      }

      &.disabled {
        color: $gray-light;
        outline: none;
        cursor: not-allowed;
      }

      // ensure row line not visible behind faces
      &:after {
        content: "";
        position: absolute;
        background-color: white;
        border-radius: 50%;
        z-index: var(--emote-rating-after-layer);
        top: 10%;
        left: 10%;
        right: 10%;
        bottom: 10%;
      }
    }
  }

  &:after {
    background-color: $gray-light;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 48%;
    left: 0;
    right: 0;
    bottom: 48%;
    width: 95%;
  }
}

.emote-rating-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
