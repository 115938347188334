.dashboard-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  border: 1px solid #e3e9f1;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0;
  background: white;

  div.btn-content {
    border-radius: inherit;
  }

  &.join-video {
    border: 1px solid #007bff;
    div.btn-content {
      color: #007bff;
      span:not(.badge) {
        color: inherit;
      }
    }
    &:hover {
      div.btn-content {
        color: white;
        background-color: #007bff;
      }
    }
  }
  &:hover:not(.btn-primary, .join-video) {
    background-color: #e3e9f1;
    div.btn-content,
    a:not(.btn),
    span:not(.badge) {
      color: rgba(0, 0, 0, 0.7);
    }
  }
  i.fa {
    font-size: 3rem;
  }

  span:not(.badge) {
    display: inline-block;
    margin-top: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: #494c4e;
  }

  &:not(.join-video) {
    span:not(.badge) {
      transition: color 0.15s ease-in-out;
    }
  }

  li {
    list-style: none;
  }
  div.btn-content,
  a:not(.btn) {
    padding: 0.5rem;
    color: #9a9d9f;
    position: relative;
    transition: color 0.15s ease-in-out;
  }
}

.ai-only-button {
  background-color: #17a2b8;
  span:not(.badge) {
    color: white;
  }
}
