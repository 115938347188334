@import "../Assets/styles/globals.scss";

.queue-avatar-img {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.3rem;
  border-radius: 50%;

  &.on-duty {
    border: 1px solid $brand-success;
  }

  &.busy {
    border: 1px solid $brand-warning;
  }
}

.powered-by-anchor {
  margin: 1rem;
  width: 180px;
  opacity: 0.6;
  transition: 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
  small {
    position: absolute;
    color: $dark;
    font-weight: 400;
    font-size: 0.8rem;
    opacity: 0.6;
    display: inline-block;
    margin-bottom: 15px;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.badge-container {
  display: inline;
  left: -10px;
  opacity: 0.8;

  &.nav {
    position: relative;
    top: -10px;
  }

  &.help-dashboard {
    position: absolute;
    top: -0px;
  }
}

.no-mentor-message {
  padding: 1rem;
}

i.lhl-spinner {
  font-size: 1.4rem;
  animation: spin 1.2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

i.priority {
  padding: 0.3rem;
  color: $brand-info;
}

i.copy {
  color: $brand-primary;
}

label.splash-label {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  &.badge-primary {
    background-color: $brand-primary;
    color: $white;
  }

  &.badge-info {
    background-color: $brand-light-blue;
    color: $brand-primary;
  }
}
