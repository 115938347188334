@import "./globals.scss";

.card {
  .card-title {
    float: left;
    margin-bottom: 0px;

    span.count {
      padding-right: 0.6rem;
      padding-left: 0.6rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      font-weight: 200;
      color: $brand-primary;
      background-color: $white;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
}
