@import "../../Assets/styles/z-indexes.css";
@import "../../Assets/styles/globals.scss";

.lhl-nav {
  z-index: var(--navbar-layer);
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  background-color: $brand-primary !important;

  div.lhl-nav-links {
    display: flex;
  }

  h1 {
    margin: 0;
    font-weight: 500;
    margin-right: 1rem;
    font-family: $brand-script;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-decoration: none;
    &.brand {
      color: $white;
    }
    &.admin-queue {
      color: $buoy-orange;
    }
  }

  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
  }
}
