@import "src/Assets/styles/globals";

.learner-page {
  .navigation {
    margin-bottom: 10px;
  }

  .home-btn {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-right: 1.5rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      background-color: #e3e9f1;
    }

    &:visited {
      color: rgba(0, 0, 0, 0.5);
    }

    i {
      margin: 0;
      color: rgba(0, 0, 0, 0.5);
      transition: inherit;
    }

    &:hover i {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .pronoun {
    font-style: italic;
    color: $dark;
    font-size: 0.7em;
  }

  .learner {
    padding: 10px;
    width: 100%;
    border: 2px solid #dddddd;
    border-radius: 4px;
    overflow-wrap: anywhere;

    @media (min-width: 576px) {
      .avatar {
        width: 15%;
        margin: 0 10px 0 0;
      }

      .avatar img {
        width: 100%;
        height: unset;
      }
    }

    .name .title {
      font-size: 2.25rem;
    }

    .btn-main:hover,
    .btn-main:focus,
    .btn-main:active {
      background-color: $blue;
      border-color: $blue;
      color: $light;
    }
  }

  .modal-dialog {
    .social-icon-muted {
      font-size: 0.9em;
    }
  }

  .social-icon-muted {
    font-size: 1.25em;
  }
}

@media (max-width: 768px) {
  .learner-page {
    .learner {
      .name {
        > div:last-child {
          margin: -10px 0;
        }
        .social-anchor {
          font-size: x-small;
        }
        .title {
          font-size: 1.5rem;
          line-height: 0.8em;
        }
      }
    }
  }
}
