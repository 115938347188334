@import "../Assets/styles/globals.scss";

.center-page-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 4rem;
    font-family: $brand-script;
    color: $brand-primary;
  }

  .oh-snap {
    font-size: 10rem;
  }

  .med-logo {
    width: 180px;
    height: auto;
    opacity: 0.6;
  }

  &.powered-by-holder {
    position: static;
    height: "240px";

    small {
      font-weight: 400;
      font-size: 1rem;
    }
  }

  &.sign-in-holder {
    .message-container {
      max-width: 400px;
    }
    small {
      font-weight: 400;
      font-size: 1rem;
    }
  }
}

.dashboard {
  display: flex;
  top: 50%;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  li {
    list-style: none;
  }
}

.content {
  margin-top: calc(1 * 2 + 1.5);
}

.padded {
  padding: 3rem;
}

.main-layout {
  margin-top: calc(1rem * 2 + 1.5rem);
}
