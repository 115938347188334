@import "../../Assets/styles/globals.scss";

.modal-btn {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none;
  border-radius: 5px;

  &.submit {
    color: $white;
    background-color: $brand-primary;
  }

  &.back {
    color: $brand-primary;
    background-color: $brand-light-blue;
  }

  &.delete {
    color: $white;
    background-color: $buoy-orange;

    &:disabled {
      color: $buoy-orange;
      background-color: $light;
    }
  }

  &.edit {
    margin: 0.5rem;
    color: $brand-primary;
    background-color: $brand-light-blue;

    &:disabled {
      color: $buoy-orange;
      background-color: $light;
    }
  }

  &.outline {
    margin: 0.2rem;
    background-color: $white;

    &.primary {
      color: $brand-primary;
      border: 1px solid $brand-primary;
    }

    &.secondary {
      color: darken($brand-light-blue, 30%);
      border: 1px solid darken($brand-light-blue, 30%);
    }

    &.danger {
      color: darken($buoy-orange, 10%);
      border: 1px solid darken($buoy-orange, 10%);
    }

    &:disabled {
      background-color: $light;
    }
  }
}
