@import "../Assets/styles/globals.scss";

.modal-dialog {
  border-radius: 6px;
}
.modal-header {
  background-color: $brand-primary !important;
}

.modal-title {
  color: $white;
}
