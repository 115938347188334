@import "src/Assets/styles/globals";
@import "src/Assets/styles/z-indexes";

.teacher-holder {
  max-height: 75vh;
  overflow-y: scroll;
  z-index: var(--mentor-status-layer);

  @media (max-width: 768px) {
    max-height: 35vh;
  }

  .teacher-list {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .teacher-mentor-item {
      img {
        width: 50px;
        min-height: 50px;
        background-color: #343a40;
        height: auto;
        border-width: 2px;
        border-style: solid;
        border-color: $brand-success;
        border-radius: 10px;
        margin-right: 0.75rem;

        &.busy {
          border-color: $brand-warning;
        }
      }

      a {
        width: "min-content";
        padding: 0;
      }
    }
  }
}
