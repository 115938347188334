.task-master-container {
  display: flex;
  align-items: center;
  width: 100%;

  .task-master-info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    .user-details-container {
      text-align: right;

      .badge {
        height: 18px;
        margin-bottom: 4px;
      }

      .details {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        text-align: right;
        position: relative;
        top: -6px;

        @media (max-width: 576px) {
          text-align: left;
          top: 0px;
          margin-top: 0.5rem;
        }
      }
    }
  }

  .rudder-tag {
    text-overflow: ellipsis;
    max-width: 230px;
    overflow: hidden;
  }
}
